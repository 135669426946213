<template>
  <div class="pageGaeRecord">
    <!--主体内容-->
    <div class="gaeRecord">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          ref="searchWrapper"
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item label="泊位编码">
            <el-input v-model="formInline.berthCode" placeholder="请输入泊位编码"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.park_name')">
            <el-input v-model="formInline.parkName" placeholder="请输入车场名称"></el-input>
          </el-form-item>

          <el-form-item label="处理操作">
            <el-select
              v-model.trim="formInline.syncState"
              style="width: 150px"
              placeholder="请选择处理操作"
            >
              <el-option label="请选择" value=""></el-option>
              <el-option label="待同步" :value="0"></el-option>
              <el-option label="已同步" :value="1"></el-option>
              <el-option label="驳回" :value="2"></el-option>
              <el-option label="归档" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="更新类型">
            <el-select
              v-model.trim="formInline.dataState"
              style="width: 150px"
              placeholder="请选择更新类型"
            >
              <el-option label="请选择" value=""></el-option>
              <el-option label="新增" :value="1"></el-option>
              <el-option label="修改" :value="2"></el-option>
              <el-option label="删除" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.processing_time')">
            <timeRangePick
              @timeChange="timeChange"
              ref="timeRangePicker"
              :defaultRange="defaultRangeValue"
              :defalutDate="defalutDate"
            />
          </el-form-item>
          <!--          v-if="$route.meta.authority.button.query" todo -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="searchData">{{ $t('button.search') }}</el-button>
            <el-button type="primary" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
      </div>

      <!--分页器-->
      <div class="pagerWrapper">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
export default {
  name: "synchronizationLog",
  components: {
    timeRangePick,
  },
  data() {
    return {
      pageSize: 15,
      page: 1,
      total: 0,
      authority: "",
      formInline: {
        berthCode: "",
        parkName: "",
        syncState: "",
        dataState: "",
        startDate: "",
        endDate: "",
      },
      defaultRangeValue: [],
      defalutDate: [],
      tableData: [],
      tableCols: [
        {
          prop: "berthCode",
          label: this.$t("list.Berth_number"),
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "updateTime",
          label: this.$t("list.update_time"),
          width: "",
        },
        {
          prop: "dataState",
          label: "更新类型",
          width: "",
          formatter: (column, row) => {
            switch (column.dataState) {
              case 1:
                return "新增";
              case 2:
                return "修改";
              case 3:
                return "删除";
              default:
                return "";
            }
          },
        },
        {
          prop: "operatorName",
          label: this.$t("list.Processing_personnel"),
          width: "",
        },
        {
          prop: "syncState",
          label: "处理操作",
          width: "",
          formatter: (column, row) => {
            switch (column.syncState) {
              case 0:
                return "待同步（待处理）";
              case 1:
                return "已同步（已处理）";
              case 2:
                return "驳回";
              case 3:
                return "归档";
              default:
                return "";
            }
          },
        },
        {
          prop: "handleTime",
          label: this.$t("list.processing_time"),
          width: "",
        },
        {
          prop: "description",
          label: "备注",
          width: "",
        },
      ],
    };
  },
  watch: {},
  computed: {},
  methods: {
    // 时间段
    timeChange(timeArr, shortcutKey) {
      console.log("shortcutKey", shortcutKey);
      this.formInline.startDate = timeArr[0];
      this.formInline.endDate = timeArr[1];
      if (shortcutKey) {
        this.searchData();
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 清空
    resetForm() {
      this.$refs["searchWrapper"].clearValidate();
      this.formInline = {
        berthCode: "",
        parkName: "",
        syncState: "",
        dataState: "",
        startDate: "",
        endDate: "",
      };
      this.$refs.timeRangePicker.resetTime();
      this.searchData();
    },
    // 首页列表查询
    searchData() {
      this.$axios
        .get("/acb/2.0/syncAlphaPark/syncLog", {
          data: {
            page: this.page,
            pageSize: this.pageSize,
            berthCode: this.formInline.berthCode,
            parkName: this.formInline.parkName,
            syncState: this.formInline.syncState,
            dataState: this.formInline.dataState,
            startDate: this.formInline.startDate,
            endDate: this.formInline.endDate,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.tableData = [];
            this.total = 0;
            this.$alert(res.desc);
          }
        });
    },
  },
  created() {
    this.authority = this.$setAuthority("synchronizationLog");
    console.log("权限为==>", this.$setAuthority("synchronizationLog"));
  },
  mounted() {
    this.searchData();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.gaeRecord {
  overflow: hidden;
  background: #fff;



  .tableWrapper {
    .el-table {
      width: 100%!important;
    }
  }
}
</style>
